var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center p-3" },
    [
      _c("b-spinner", { staticClass: "mx-1", attrs: { small: "" } }),
      _vm._v(" Carregando... ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }