var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-progress", {
    staticClass: "ui-progress",
    attrs: { value: _vm.value, max: 100, "show-progress": "", height: "20" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }