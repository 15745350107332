export default [
  {
    path: "/sistema/usuarios",
    name: "sistema.usuario.lista",
    
    meta: {
        menu: 'sistema.usuario',
        authRequired: true,
        title: "Usuários",
        breadcrumbs: [
            {
                text: "Adm. do sistema",
            },
            {
                text: "Usuários",
                active: true,
            },
        ],
    },
    component: () => import("../views/pages/sistema/usuario/Lista.vue"),
  },
  {
    path: "/sistema/usuarios/criar",
    name: "sistema.usuario.criar",
    
    meta: {
      menu: 'sistema.usuario',
      authRequired: true,
      title: "Criar Usuário",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Usuários",
        },
        {
          text: "Criar Usuário",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/usuario/Form.vue"),
  },
  {
    path: "/sistema/usuarios/editar/:id",
    name: "sistema.usuario.editar",
    
    meta: {
      menu: 'sistema.usuario',
      authRequired: true,
      title: "Editar Usuário",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Usuários",
        },
        {
          text: "Editar Usuário",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/usuario/Form.vue"),
  },
  {
    path: "/sistema/usuarios/visualizar/:id",
    name: "sistema.usuario.visualizar",
    
    meta: {
      menu: 'sistema.usuario',
      authRequired: true,
      title: "Visualizar Usuário",
      editavel: false,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Usuários",
        },
        {
          text: "Visualizar Usuário",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/usuario/Form.vue"),
  },
  {
    path: "/alterar-senha",
    name: "sistema.alterar-senha",
    
    meta: {
      menu: 'sistema.usuario',
      authRequired: true,
      title: "Alterar senha",
      editavel: false,
      breadcrumbs: [
        {
          text: "Alterar senha",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/usuario/AlterarSenha.vue"),
  },
];
  