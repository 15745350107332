<template>
    <div class="text-center p-3">
        <b-spinner small class="mx-1"></b-spinner>
        Carregando...
    </div>
</template>
<script>
export default {
  methods: { }
};
</script>

