export default [
  {
    path: "/cadastros/motivos-glosa",
    name: "cadastros.motivoglosa.lista",

    meta: {
      menu: "cadastros.motivoglosa",
      authRequired: true,
      title: "Motivo Glosa",
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivo Glosa",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivoglosa/Lista.vue"),
  },
  {
    path: "/cadastros/motivos-glosa/criar",
    name: "cadastros.motivoglosa.criar",

    meta: {
      menu: "cadastros.motivoglosa",
      authRequired: true,
      title: "Criar Motivo Glosa",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivo Glosa",
        },
        {
          text: "Criar Motivo Glosa",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivoglosa/Form.vue"),
  },
  {
    path: "/cadastros/motivos-glosa/editar/:id",
    name: "cadastros.motivoglosa.editar",

    meta: {
      menu: "cadastros.motivoglosa",
      authRequired: true,
      title: "Editar Motivo Glosa",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivo Glosa",
        },
        {
          text: "Editar Motivo Glosa",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivoglosa/Form.vue"),
  },
  {
    path: "/cadastros/motivos-glosa/visualizar/:id",
    name: "cadastros.motivoglosa.visualizar",

    meta: {
      menu: "cadastros.motivoglosa",
      authRequired: true,
      title: "Visualizar Motivo Glosa",
      editavel: false,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivo Glosa",
        },
        {
          text: "Visualizar Motivo Glosa",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivoglosa/Form.vue"),
  },
];
