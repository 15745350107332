export default [
  {
    path: "/faturamento/lote/upload",
    name: "faturamento.lote.upload",
    meta: {
      menu: 'faturamento.upload',
      authRequired: true,
      title: 'Lotes de faturamento',
      breadcrumbs: [
        {
          text: "Lotes de faturamento",
        },
        {
          text: "Upload lotes de faturamento",
          active: true,
        },
      ]
    },
    component: () => import("../views/pages/faturamento/Upload.vue"),
  },
  {
    path: "/faturamento/lotes",
    name: "faturamento.lotes",
    meta: {
      menu: 'faturamento.lotes',
      authRequired: true,
      title: "Listar Lotes de Faturamento",
      breadcrumbs: [
        {
          text: "Lotes de faturamento",
        },
        {
          text: "Listar",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/lotes/Listar.vue"),
  },
  {
    path: "/faturamento/lotes/detalhe/:id",
    name: "faturamento.lotes.detalhe",
    meta: {
      menu: 'faturamento.lotes',
      authRequired: true,
      title: "Detalhe de Lote de faturamento",
      editavel: false,
      breadcrumbs: [
        {
          text: "Lotes de faturamento"
        },
        {
          text: "Detalhe de Lote",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/lotes/DetalheLote.vue"),
  },
  {
    path: "/faturamento/lotes/auditar/:id",
    name: "faturamento.lotes.auditar",
    meta: {
      menu: 'faturamento.lotes',
      authRequired: true,
      title: "Auditar Lote de faturamento",
      editavel: true,
      breadcrumbs: [
        {
          text: "Lotes de faturamento"
        },
        {
          text: "Auditoria de Lote",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/lotes/DetalheLote.vue"),
  },
  {
    path: "/faturamento/lotes/detalhe-guia/visualizar/:arquivoId/:guiaIndex/:numeroLote",
    name: "faturamento.lotes.detalheguia.visualizar",
    meta: {
      menu: 'faturamento.lotes',
      authRequired: true,
      title: "Detalhe da Guia",
      editavel: false,
      breadcrumbs: [
        {
          text: "Lotes de faturamento"
        },
        {
          text: "Lote",
          active: false,
        },
        {
          text: "", // numero do lote a ser inserido na página
          active: false,
        },
        {
          text: "Visualizar Guia",
          active: false,
        },
      ],
    },
    component: () => import("../views/pages/lotes/DetalheGuia.vue"),
  },
  {
    path: "/faturamento/lotes/detalhe-guia/auditar/:arquivoId/:guiaIndex/:numeroLote",
    name: "faturamento.lotes.detalheguia.auditar",
    meta: {
      menu: 'faturamento.lotes',
      authRequired: true,
      title: "Detalhe da Guia",
      editavel: true,
      breadcrumbs: [
        {
          text: "Lotes de faturamento"
        },
        {
          text: "Lote",
          active: false,
        },
        {
          text: "", // numero do lote a ser inserido na página
          active: false,
        },
        {
          text: "Auditar Guia",
          active: false,
        },
      ],
    },
    component: () => import("../views/pages/lotes/DetalheGuia.vue"),
  },
  {
    path: "/faturamento/lotes/detalhe-guia/visualizar/:arquivoId/:guiaIndex/:numeroLote/:itemGuiaIds",
    name: "faturamento.lotes.formauditoria.visualizar",
    meta: {
      menu: 'faturamento.lotes',
      authRequired: true,
      title: "Detalhe da Guia",
      editavel: false,
      breadcrumbs: [
        {
          text: "Lotes de faturamento"
        },
        {
          text: "Guia",
          active: false,
        },
        {
          text: "", // numero do lote a ser inserido na página
          active: false,
        },
        {
          text: "Visualizar Guia",
          active: false,
        },
      ],
    },
    component: () => import("../views/pages/lotes/FormAuditoria.vue"),
  },
  {
    path: "/faturamento/lotes/detalhe-guia/auditar/:arquivoId/:guiaIndex/:numeroLote/:itemGuiaIds",
    name: "faturamento.lotes.formauditoria.auditar",
    meta: {
      menu: 'faturamento.lotes',
      authRequired: true,
      title: "Detalhe da Guia",
      editavel: true,
      breadcrumbs: [
        {
          text: "Lotes de faturamento"
        },
        {
          text: "Guia",
          active: false,
        },
        {
          text: "", // numero do lote a ser inserido na página
          active: false,
        },
        {
          text: "Auditar Guia",
          active: false,
        },
      ],
    },
    component: () => import("../views/pages/lotes/FormAuditoria.vue"),
  },
];
  