<template>
  <div id="app">
    <notifications group="custom-template"
            :width="500"
            :duration="5000"
            position="top center">
      <template slot="body" slot-scope="props">
        <b-alert
          @click="props.close"
          :variant="props.item.type"
          dismissible
          show
          class="text-center px-2 mb-0"
        >
          <i
            :class="`${getAlertIconClass(props.item.type)} d-block display-4`"
          ></i>
          <h5 :class="`text-${props.item.type}`" v-if="props.item.title">{{props.item.title}}</h5>
          <p v-html="props.item.text" class="text-start px-4"></p>
        </b-alert>
      </template>
    </notifications>
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store, this.$route) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  methods: {
    clearNotification: notificationMethods.clear,
    getAlertIconClass(type) {
      switch(type){
        case 'success':
          return 'uil uil-check-circle text-success'
        case 'danger':
          return 'uil uil-exclamation-octagon text-danger'
        case 'warning':
          return 'uil uil-exclamation-triangle text-warning'
        case 'info':
          return 'uil uil-question-circle text-info'
      }
      return '';
    }
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    },
  },
  mounted() {
  }
};
</script>
