export default [
  {
    path: "/cadastros/tabelas",
    name: "cadastros.tabelatuss.lista",

    meta: {
      menu: "cadastros.tabelatuss",
      authRequired: true,
      title: "Tabelas",
      breadcrumbs: [
        {
          text: "Cadastros",
        },
        {
          text: "Tabelas",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/tabelatuss/Lista.vue"),
  },
  {
    path: "/cadastros/tabelas/criar",
    name: "cadastros.tabelatuss.criar",

    meta: {
      menu: "cadastros.tabelatuss",
      authRequired: true,
      title: "Criar Tabela",
      editavel: true,
      breadcrumbs: [
        {
          text: "Cadastros",
        },
        {
          text: "Tabelas",
        },
        {
          text: "Criar Tabela",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/tabelatuss/Form.vue"),
  },
  {
    path: "/cadastros/tabelas/editar/:id",
    name: "cadastros.tabelatuss.editar",

    meta: {
      menu: "sistema.tabelatuss",
      authRequired: true,
      title: "Editar Tabela",
      editavel: true,
      breadcrumbs: [
        {
          text: "Cadastros",
        },
        {
          text: "Tabelas",
        },
        {
          text: "Editar Tabela",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/tabelatuss/Form.vue"),
  },
  {
    path: "/cadastros/tabelas/visualizar/:id",
    name: "cadastros.tabelatuss.visualizar",

    meta: {
      menu: "cadastros.tabelatuss",
      authRequired: true,
      title: "Visualizar Tabela",
      editavel: false,
      breadcrumbs: [
        {
          text: "Cadastros",
        },
        {
          text: "Tabelas",
        },
        {
          text: "Visualizar Tabela",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/tabelatuss/Form.vue"),
  },
];
