<template>
  <div :class="`col-md-${cols} col-xxl-${colsXL ? colsXL : cols}`">
    <b-form-group
      :label="label"
      :label-for="id"
      class="mb-3 form-group-input"
    >
      <b-input-group class="mt-3">
        <b-form-input
          :id="id"
          :name="ifnull(name, id)"
          :type="ifnull(type, 'text')"
          :disabled="disabled"
          v-bind:value="value"
          v-on:input="$emit('input', $event)"
          :class="`${isDisplayErrors() ? 'is-invalid' : ''}`"
        />
        <b-input-group-append>
          <slot name="append" />
        </b-input-group-append>
      </b-input-group>

      <span class="text-danger" v-if="isDisplayErrors()">{{ modelErrors.first(name) }}</span>
    </b-form-group>
  </div>
</template>

<script>
import { ifnull } from '../../utils/mixins.string';
export default {
  name: "FormInput",
  data() {
    return {
        ifnull
    };
  },
  $_veeValidate: {
    value() {
      return this.$el.value;
    },
    name() {
      return this.name;
    }
  },
  props: {
    cols: {
        type: Number,
        required: true
    },
    colsXL: Number,
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    displayErrors: {
        type: Boolean,
        default: true
    },
    id: String,
    label: String,
    modelErrors: Object,
    name: {
        type: String,
        required: true
    },
    type: String,
    value: String,
  },
  mounted() {
    this.$el.value = this.value;
  },
  methods: {
      isDisplayErrors() {
        return this.modelErrors && this.modelErrors.has(this.name) && (this.displayErrors == null || this.displayErrors);
      }
  }
};
</script>
