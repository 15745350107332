import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import router from "./router";

import vco from "v-click-outside";
import VueSweetalert2 from "vue-sweetalert2";
import VueSlideBar from "vue-slide-bar";
import Vuelidate from "vuelidate";
import store from "@/state/store";

import VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
import messagesPtBr from 'vee-validate/dist/locale/pt_BR';
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
Validator.localize('pt_BR', messagesPtBr);

import App from "./App.vue";
import "./plugins/UiComponent";
// As a plugin
import VueMask from "v-mask";

import Lightbox from "vue-easy-lightbox";

Vue.use(Lightbox);

import "@/assets/scss/app.scss";

import Notifications from 'vue-notification'
Vue.use(Notifications)

Vue.component("VueSlideBar", VueSlideBar);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(Vuelidate);
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.use(VueMask);

import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/pt-br';
Vue.use(DatePicker);

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

import UiLoading from './components/Ui/UiTableLoading';
Vue.component('ui-loading', UiLoading)

// Filtros
import moment from 'moment';
Vue.filter('dataHora', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm');
  }
})
Vue.filter('data', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
})
Vue.filter('hora', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss');
  }
})
Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
  });
  return formatter.format(value);
});
Vue.filter('toDecimal', function (value, precision) {
  if (typeof precision == 'undefined') precision = 2;

  var result;
  if (typeof value === "number") {
    result = value;
  } else {
    result = parseFloat(value.replace('R$ ', '').trim().replace(/\./, '').replace(/,/, '.'));
  }
  
  return parseFloat(result.toFixed(precision));
});

import money from 'v-money'
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: false,
});

Vue.prototype.$tableItens = [10, 25, 50, 75, 100, 200]
Vue.prototype.$tableItensDefault = 25

Vue.directive('authorize', {
  terminal: true,
  bind: execAuthorize,
  inserted: execAuthorize,
  update: execAuthorize,
  componentUpdated: execAuthorize,
})
Vue.prototype.$hasPerm = hasPerm;
function hasPerm(reqRoles) {
  if (!localStorage.getItem("user")) return false;

  var userRoles = JSON.parse(localStorage.getItem("user")).modulosUsuarioStr.split(',');
  if (Array.isArray(reqRoles)) {
    for (var idx = 0; idx < reqRoles.length; idx++) {
      if (userRoles.includes(reqRoles[idx])) {
        return true;
      }
    }
  } else {
    return userRoles.includes(reqRoles);
  }
  return false;
}
function execAuthorize(el, roles, vnode) {
  var exec = roles.arg || 'remove';
  if (roles.value != null && !hasPerm(roles.value)) {
    switch (exec) {
      case 'disable':
        // eslint-disable-next-line no-prototype-builtins
        if (vnode.hasOwnProperty('componentInstance')) {
          // eslint-disable-next-line no-prototype-builtins
          if (vnode.componentInstance.hasOwnProperty('_data') && vnode.componentInstance.$data.hasOwnProperty('allow')) {
            vnode.componentInstance.$data.allow = false;
          } else {
            vnode.componentInstance.$props.disabled = true;
          }
        } else {
          //el.attributes.disabled = false;
        }
        break;

      case 'remove':
      default:
        el.remove()
    }
  }
}

// APP.
Vue.prototype.$app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
