export default [
  {
    path: "/sistema/hospitais",
    name: "sistema.hospital.lista",
    meta: {
      menu: 'sistema.hospital',
      authRequired: true,
      title: "Hospital",
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Hospital",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/hospital/Lista.vue"),
  },
  {
    path: "/sistema/hospitais/criar",
    name: "sistema.hospital.criar",
    
    meta: {
      menu: 'sistema.hospital',
      authRequired: true,
      title: "Criar Hospital",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Hospitais",
        },
        {
          text: "Criar Hospital",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/hospital/Form.vue"),
  },
  {
    path: "/sistema/hospitais/editar/:id",
    name: "sistema.hospital.editar",
    
    meta: {
      menu: 'sistema.hospital',
      authRequired: true,
      title: "Editar Hospital",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Hospitais",
        },
        {
          text: "Editar Hospital",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/hospital/Form.vue"),
  },
  {
    path: "/sistema/hospitais/visualizar/:id",
    name: "sistema.hospital.visualizar",
    
    meta: {
      menu: 'sistema.hospital',
      authRequired: true,
      title: "Visualizar Hospital",
      editavel: false,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Hospitais",
        },
        {
          text: "Visualizar Hospital",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/hospital/Form.vue"),
  },
  {
    path: "/sistema/hospital/usuarios/:id",
    name: "sistema.hospital.usuarios.lista",
    
    meta: {
      menu: 'sistema.hospital',
      authRequired: true,
      title: "Hospital / Usuários",
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Hospital",
        },
        {
          text: "Usuários",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/hospital/Usuarios.vue"),
  },
];
  