export default [
  {
    path: "/erro/acesso-negado",
    name: "erro.forbidden",
    meta: {
      menu: 'home',
      authRequired: false
    },
    component: () => import("../views/pages/utility/403.vue"),
  },
  {
    path: "/erro/nao-encontrado",
    name: "erro.not.found",
    meta: {
      menu: 'home',
      authRequired: false
    },
    component: () => import("../views/pages/utility/404.vue"),
  },
  {
    path: "/erro/server-error",
    name: "erro.server.error",
    meta: {
      menu: 'home',
      authRequired: false
    },
    component: () => import("../views/pages/utility/500.vue"),
  }
];
  