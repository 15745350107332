export default [
  {
    path: "/cadastros/procedimentos",
    name: "cadastros.procedimento.lista",

    meta: {
      menu: "cadastros.procedimento",
      authRequired: true,
      title: "Procedimentos",
      breadcrumbs: [
        {
          text: "Cadastros",
        },
        {
          text: "Procedimentos",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/procedimento/Lista.vue"),
  },
  {
    path: "/cadastros/procedimentos/criar",
    name: "cadastros.procedimento.criar",

    meta: {
      menu: "cadastros.procedimento",
      authRequired: true,
      title: "Criar Procedimento",
      editavel: true,
      breadcrumbs: [
        {
          text: "Cadastros",
        },
        {
          text: "Procedimentos",
        },
        {
          text: "Criar Procedimento",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/procedimento/Form.vue"),
  },
  {
    path: "/cadastros/procedimentos/editar/:id",
    name: "cadastros.procedimento.editar",

    meta: {
      menu: "sistema.procedimento",
      authRequired: true,
      title: "Editar Procedimento",
      editavel: true,
      breadcrumbs: [
        {
          text: "Cadastros",
        },
        {
          text: "Procedimentos",
        },
        {
          text: "Editar Procedimento",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/procedimento/Form.vue"),
  },
  {
    path: "/cadastros/procedimentos/visualizar/:id",
    name: "cadastros.procedimento.visualizar",

    meta: {
      menu: "cadastros.procedimento",
      authRequired: true,
      title: "Visualizar Procedimento",
      editavel: false,
      breadcrumbs: [
        {
          text: "Cadastros",
        },
        {
          text: "Procedimentos",
        },
        {
          text: "Visualizar Procedimento",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/procedimento/Form.vue"),
  },
];
