import store from "@/state/store";

export default [
    {
      path: "/login",
      name: "login",
      component: () => import("../views/pages/account/login"),
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          // If the user is already logged in
          if (store.getters["authfack/loggedIn"]) {
            // Redirect to the home page instead
            next({
              name: "home",
            });
          } else {
            // Continue to the login page
            next();
          }
        },
      },
    },
    {
      path: "/restaurar-senha",
      name: "restaurar-senha",
      component: () => import("../views/pages/account/restaurar-senha"),
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          // If the user is already logged in
          if (store.getters["authfack/loggedIn"]) {
            // Redirect to the home page instead
            next({
              name: "home",
            });
          } else {
            next();
          }
        },
      },
    },
    {
      path: "/recuperar-senha/:codigo",
      name: "recuperar-senha",
      component: () => import("../views/pages/account/recuperar-senha"),
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          // If the user is already logged in
          if (store.getters["authfack/loggedIn"]) {
            // Redirect to the home page instead
            next({
              name: "home",
            });
          } else {
            next();
          }
        },
      },
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        authRequired: true,
        beforeResolve(routeTo, routeFrom, next) {
          store.dispatch("authfack/logout");
          const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
            route.push("/login")
          );
          // Navigate back to previous page, or home as a fallback
          next(
            authRequiredOnPreviousRoute
              ? {
                  name: "home",
                }
              : {
                  ...routeFrom,
                }
          );
        },
      },
    }
  ];
  