export default [
  {
    path: "/cadastros/motivos-pendencia",
    name: "cadastros.motivospendenciaauditoria.lista",

    meta: {
      menu: "cadastros.motivospendenciaauditoria",
      authRequired: true,
      title: "Motivos Pendência de Auditoria",
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivos Pendências de Auditoria",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivospendenciaauditoria/Lista.vue"),
  },
  {
    path: "/cadastros/motivos-pendencia/criar",
    name: "cadastros.motivospendenciaauditoria.criar",

    meta: {
      menu: "cadastros.motivospendenciaauditoria",
      authRequired: true,
      title: "Criar Motivo Pendência de Auditoria",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivos Pendências de Auditoria",
        },
        {
          text: "Criar Motivo Pendência de Auditoria",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivospendenciaauditoria/Form.vue"),
  },
  {
    path: "/cadastros/motivos-pendencia/editar/:id",
    name: "cadastros.motivospendenciaauditoria.editar",

    meta: {
      menu: "sistema.motivospendenciaauditoria",
      authRequired: true,
      title: "Editar Motivo Pendência de Auditoria",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivos Pendências de Auditoria",
        },
        {
          text: "Editar Motivo Pendência de Auditoria",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivospendenciaauditoria/Form.vue"),
  },
  {
    path: "/cadastros/motivos-pendencia/visualizar/:id",
    name: "cadastros.motivospendenciaauditoria.visualizar",

    meta: {
      menu: "cadastros.motivospendenciaauditoria",
      authRequired: true,
      title: "Visualizar Motivo Pendência de Auditoria",
      editavel: false,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Motivos Pendência de Auditoria",
        },
        {
          text: "Visualizar Motivo Pendência de Auditoria",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/cadastros/motivospendenciaauditoria/Form.vue"),
  },
];
