var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("notifications", {
        attrs: {
          group: "custom-template",
          width: 500,
          duration: 5000,
          position: "top center"
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c(
                  "b-alert",
                  {
                    staticClass: "text-center px-2 mb-0",
                    attrs: {
                      variant: props.item.type,
                      dismissible: "",
                      show: ""
                    },
                    on: { click: props.close }
                  },
                  [
                    _c("i", {
                      class:
                        _vm.getAlertIconClass(props.item.type) +
                        " d-block display-4"
                    }),
                    props.item.title
                      ? _c("h5", { class: "text-" + props.item.type }, [
                          _vm._v(_vm._s(props.item.title))
                        ])
                      : _vm._e(),
                    _c("p", {
                      staticClass: "text-start px-4",
                      domProps: { innerHTML: _vm._s(props.item.text) }
                    })
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }