<template>
  <div :class="`col-md-${cols}`">
    <div v-if="legend" class="bv-no-focus-ring col-form-label pt-0">{{ legend }}</div>
    <label>
      <switches
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        :disabled="disabled"
        :color="(invertColor ? (value ? 'danger' : 'dark') : (value ? 'success' : 'danger'))"
        :title="value ? labelTrue : labelFalse"
      ></switches>
      <span style="vertical-align: top; margin-left: 15px; top: -3px; position: relative;">{{label}}</span>
    </label>
    <span class="text-danger d-block" v-if="isDisplayErrors()">{{ modelErrors.first(name) }}</span>
  </div>
</template>

<script>
import Switches from "vue-switches";

export default {
  name: "FormSwich",
  components: {
    Switches
  },
  $_veeValidate: {
    value() {
      return this.$el.value;
    },
    name() {
      return this.name;
    }
  },
  props: {
    modelErrors: {
        type: Object
    },
    value: {
        type: Boolean
    },
    cols: {
        type: Number,
        required: true
    },
    invertColor: {
      type: Boolean,
      default: false,
    },
    label: {
        type: String
    },
    name: {
        type: String,
        required: true
    },
    id: {
        type: String
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    displayErrors: {
        type: Boolean,
        default: true
    },
    labelTrue: {
      type: String,
      default: 'Sim'
    },
    labelFalse: {
      type: String,
      default: 'Não'
    },
    legend: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.$el.value = this.value;
  },
  methods: {
      isDisplayErrors() {
        return this.modelErrors && this.modelErrors.has(this.name) && (this.displayErrors == null || this.displayErrors);
      }
  }
};
</script>
