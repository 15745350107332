export default [
    {
      path: "/sistema/convenios",
      name: "sistema.convenio.lista",
      
      meta: {
        menu: 'sistema.convenio',
        authRequired: true,
        title: "Convênios",
        breadcrumbs: [
          {
            text: "Adm. do sistema",
          },
          {
            text: "Convênios",
            active: true,
          },
        ],
      },
      component: () => import("../views/pages/sistema/convenio/Lista.vue"),
    },
    {
      path: "/sistema/convenios/criar",
      name: "sistema.convenio.criar",
      
      meta: {
        menu: 'sistema.convenio',
        authRequired: true,
        title: "Criar Convênio",
        editavel: true,
        breadcrumbs: [
          {
            text: "Adm. do sistema",
          },
          {
            text: "Convênios",
          },
          {
            text: "Criar Convênio",
            active: true,
          },
        ],
      },
      component: () => import("../views/pages/sistema/convenio/Form.vue"),
    },
    {
      path: "/sistema/convenios/editar/:id",
      name: "sistema.convenio.editar",
      
      meta: {
        menu: 'sistema.convenio',
        authRequired: true,
        title: "Editar Convênio",
        editavel: true,
        breadcrumbs: [
          {
            text: "Adm. do sistema",
          },
          {
            text: "Convênios",
          },
          {
            text: "Editar Convênio",
            active: true,
          },
        ],
      },
      component: () => import("../views/pages/sistema/convenio/Form.vue"),
    },
    {
      path: "/sistema/convenios/visualizar/:id",
      name: "sistema.convenio.visualizar",
      
      meta: {
        menu: 'sistema.convenio',
        authRequired: true,
        title: "Visualizar Convênio",
        editavel: false,
        breadcrumbs: [
          {
            text: "Adm. do sistema",
          },
          {
            text: "Convênios",
          },
          {
            text: "Visualizar Convênio",
            active: true,
          },
        ],
      },
      component: () => import("../views/pages/sistema/convenio/Form.vue"),
    },
    {
      path: "/sistema/convenio/usuarios/:id",
      name: "sistema.convenio.usuarios.lista",
      
      meta: {
        menu: 'sistema.convenio',
        authRequired: true,
        title: "Convênios / Usuários",
        breadcrumbs: [
          {
            text: "Adm. do sistema",
          },
          {
            text: "Convênios",
          },
          {
            text: "Usuários",
            active: true,
          },
        ],
      },
      component: () => import("../views/pages/sistema/convenio/Usuarios.vue"),
    },
  ];
  