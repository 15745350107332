import Swal from "sweetalert2";

export function messageSuccess(component, message, title = null) {
  component.$notify({
    group: "custom-template",
    text: message,
    title,
    type: "success",
  });
}
export function messageError(component, message, title = null) {
  component.$notify({
    group: "custom-template",
    text: message,
    title,
    type: "danger",
  });
}
export function messageWarning(component, message, title = null) {
  component.$notify({
    group: "custom-template",
    text: message,
    title,
    type: "warning",
  });
}
export function messageInfo(component, message, title = null) {
  component.$notify({
    group: "custom-template",
    text: message,
    title,
    type: "info",
  });
}
export function messageConfirm(component, message, title = null) {
  return Swal.fire({
    title: title,
    text: message,
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Sim",
    denyButtonText: `Não`,
  });
}
