import axios from "axios";
import { authHeader } from "./auth-header";
import { messageError } from '@/utils/messages'
import Vue from "vue";

const apiService = () => {
  const http = axios.create({ 
    baseURL: `${process.env.VUE_APP_APIURL}`
  });

  http.interceptors.request.use((request) => {
    const header = authHeader();
    if (header.Authorization) {
      request.headers.common.Authorization = header.Authorization;
    }

    return request;
  });

  http.interceptors.response.use(
    (ok_response) => {
      return ok_response;
    },
    (err_response) => {
      //erro de CORS quando não está logado e tenta acessar uma url que necessita login e ela retorna 401
      //demais erros continuam funcionando o codigo
      if (err_response.response.status == 0) {
        localStorage.removeItem("user");
        window.location.href = '/login'
      }

      // Erro de permissão de acesso: o usuário não tem acesso a alguma parte da página solicitada.
      if (err_response.response.status == 403) {
        messageError(Vue.prototype.$app, 'Acesso restrito a um dos componentes solicitados.')
      }

      return Promise.reject(err_response);
    }
  );

  return http;
};

export const api = apiService();

function param2queryLoop(params, prefix) {
  prefix = prefix ? prefix + "." : "";

  var result = [];
  for(const key in params) {
    if (
      typeof params[key] == 'undefined' ||
      [
        'paginacao.totalPaginas',
        'paginacao.totalRegistros',
      ].indexOf(prefix + key) > -1 ||
      params[key] === ''
    ) {
      // ignore.
    } 
    else if (Object.prototype.toString.call(params[key]) === '[object Date]') {
      result.push(prefix + key + "=" + encodeURI(params[key].toLocaleString('en-US')));
    }
    else if (Array.isArray(params[key])) {
      params[key].forEach(vl => {
        result.push(prefix + key + "=" + encodeURI(vl));  
      });
      
    }
    else if (typeof params[key] == 'object') {
      result = result.concat(param2queryLoop(params[key], prefix + key));
    } else {
      result.push(prefix + key + "=" + encodeURI(params[key]));
    }
  }
  return result.join('&');
}

export const param2query = (param) => {
  return param2queryLoop(param, '');
}
