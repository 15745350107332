var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "col-md-" + _vm.cols }, [
    _vm.legend
      ? _c("div", { staticClass: "bv-no-focus-ring col-form-label pt-0" }, [
          _vm._v(_vm._s(_vm.legend))
        ])
      : _vm._e(),
    _c(
      "label",
      [
        _c("switches", {
          attrs: {
            value: _vm.value,
            disabled: _vm.disabled,
            color: _vm.invertColor
              ? _vm.value
                ? "danger"
                : "dark"
              : _vm.value
              ? "success"
              : "danger",
            title: _vm.value ? _vm.labelTrue : _vm.labelFalse
          },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event)
            }
          }
        }),
        _c(
          "span",
          {
            staticStyle: {
              "vertical-align": "top",
              "margin-left": "15px",
              top: "-3px",
              position: "relative"
            }
          },
          [_vm._v(_vm._s(_vm.label))]
        )
      ],
      1
    ),
    _vm.isDisplayErrors()
      ? _c("span", { staticClass: "text-danger d-block" }, [
          _vm._v(_vm._s(_vm.modelErrors.first(_vm.name)))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }