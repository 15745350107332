export default [ 
  {
    path: "/sistema/perfis",
    name: "sistema.perfil.lista",
    
    meta: {
      menu: 'sistema.perfil',
      authRequired: true,
      title: "Perfis de Acesso",
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Perfis de Acesso",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/perfil/Lista.vue"),
  },
  {
    path: "/sistema/perfis/criar",
    name: "sistema.perfil.criar",
    
    meta: {
      menu: 'sistema.perfil',
      authRequired: true,
      title: "Criar Perfil",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Hospitais",
        },
        {
          text: "Criar Perfil",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/perfil/Form.vue"),
  },
  {
    path: "/sistema/perfis/editar/:id",
    name: "sistema.perfil.editar",
    
    meta: {
      menu: 'sistema.perfil',
      authRequired: true,
      title: "Editar Perfil",
      editavel: true,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Hospitais",
        },
        {
          text: "Editar Perfil",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/perfil/Form.vue"),
  },
  {
    path: "/sistema/perfis/visualizar/:id",
    name: "sistema.perfil.visualizar",
    
    meta: {
      menu: 'sistema.perfil',
      authRequired: true,
      title: "Visualizar Perfil",
      editavel: false,
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Hospitais",
        },
        {
          text: "Visualizar Perfil",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/perfil/Form.vue"),
  },
  {
    path: "/sistema/perfil/usuarios/:id",
    name: "sistema.perfil.usuarios.lista",
    
    meta: {
      menu: 'sistema.perfil',
      authRequired: true,
      title: "Perfil / Usuários",
      breadcrumbs: [
        {
          text: "Adm. do sistema",
        },
        {
          text: "Perfil",
        },
        {
          text: "Usuários",
          active: true,
        },
      ],
    },
    component: () => import("../views/pages/sistema/perfil/Usuarios.vue"),
  },
];
  