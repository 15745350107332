export default [
  {
    path: "/relatorio/auditoria-retrospectiva",
    name: "relatorios.auditoria-retrospectiva",
    meta: {
      menu: 'relatorios.auditoria-retrospectiva',
      authRequired: true,
      title: 'Relatório Auditoria Retrospectiva',
      breadcrumbs: [
        {
          text: "Relatórios",
          active: true,
        },
      ]
    },
    component: () => import("../views/pages/relatorios/RelAuditoriaRetrospectiva.vue"),
  },
  {
    path: "/relatorio/faturamento",
    name: "relatorios.faturamento",
    meta: {
      menu: 'relatorios.faturamento',
      authRequired: true,
      title: 'Relatório de Faturamento',
      breadcrumbs: [
        {
          text: "Relatórios",
          active: true,
        },
      ]
    },
    component: () => import("../views/pages/relatorios/RelFaturamento.vue"),
  },
  {
    path: "/relatorio/retrospectiva-contas",
    name: "relatorios.retrospectiva-contas",
    meta: {
      menu: 'relatorios.retrospectiva-contas',
      authRequired: true,
      title: 'Relatório Retrospectiva (Contas médicas)',
      breadcrumbs: [
        {
          text: "Relatórios",
          active: true,
        },
      ]
    },
    component: () => import("../views/pages/relatorios/RelRetrospectivaContas.vue"),
  },
  {
    path: "/relatorio/glosas",
    name: "relatorios.glosas",
    meta: {
      menu: 'relatorios.glosas',
      authRequired: true,
      title: 'Relatório de Glosas',
      breadcrumbs: [
        {
          text: "Relatórios",
          active: true,
        },
      ]
    },
    component: () => import("../views/pages/relatorios/RelGlosas.vue"),
  },
];
  